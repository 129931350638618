<template>
  <div id="page-selects">
    <v-container grid-list-xl fluid pt-0>
      <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
        <v-card color="primary" dark>
          <v-card-text>
            {{progressMessage}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tabs center-active>
        <v-tab style="text-transform: capitalize" @click.native="retrieveBar"> Visit Count By Date </v-tab>
        <v-tab-item eager>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12">
              <v-card>
                <div class="layout row ma-0">
                  <div class="sm12 xs12 flex text-sm-center py-3 black--text div-1">
                    <v-row>
                      <v-col cols="11">
                        <span class="headline">Visit Count By Date Dashboard</span>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn
                              v-bind="attrs"
                              color="#E90587"
                              icon
                              v-on="on"
                              dark
                              @click.native="retrieveAll()"
                            >
                              <v-icon
                                color="#E90587"
                                dark
                              >
                                mdi-refresh
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>Refresh</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-progress-linear
                      :active="loading"
                      :indeterminate="loading"
                      absolute
                      bottom
                      color="#E90587"
                    ></v-progress-linear>
                    <!-- <v-toolbar>
                      <v-btn icon>
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>

                      <v-toolbar-title>My Recipes</v-toolbar-title>

                      <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                      ></v-progress-linear>

                      <v-spacer></v-spacer>

                      <v-btn icon>
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>

                      <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </v-toolbar> -->
                    <!-- <v-spacer></v-spacer>
                    s -->
                    <!-- <v-toolbar flat class="sm12 xs12 flex text-sm-center py-3 white--text div-1">
                      <span class="headline">Visit Count By Date Dashboard</span>
                    </v-toolbar> -->
                  </div>
                </div>
              </v-card>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Criteria
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" sm="12" lg="12">
                            <div>
                              <v-toolbar flat>
                                <v-row>
                                  <v-col cols="12" sm="12" md="4" class="pt-1 mt-1 pb-0 mb-0">
                                    <v-dialog
                                      ref="dialogReceivedDateFromTo"
                                      v-model="dialog.dialogReceivedDateFromTo"
                                      :return-value.sync="criteria.ReceivedDateFromTo"
                                      persistent
                                      width="290px">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense
                                          hide-details
                                          outlined
                                          v-model="dateRangeText"
                                          label="Date"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="criteria.ReceivedDateFromTo"
                                        range
                                        scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dialog.dialogReceivedDateFromTo = false">{{$t('cancel')}}</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialogReceivedDateFromTo.save(criteria.ReceivedDateFromTo)">{{$t('ok')}}</v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-col>
                                  <v-col cols="12" class="pt-0 pb-2 mb-0" lg="3">
                                    <v-radio-group v-model="typeX" row dense hide-details>
                                      <v-radio class='text--primary'
                                        value="i"
                                      >
                                        <template v-slot:label>
                                          <div><span class='text--primary'>รายวัน</span></div>
                                        </template>
                                      </v-radio>
                                      <v-radio
                                        value="m"
                                      >
                                        <template v-slot:label>
                                          <div><span class='text--primary'>รายเดือน</span></div>
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12" xs="12" md="1" class="px-1 pt-2 pb-0 mb-0">
                                    <v-btn dense outlined color="primary" style="text-transform: capitalize" @click.native="retrieveBar()">Retrieve</v-btn>
                                  </v-col>
                                  <v-col cols="12" xs="12" md="1" class="px-1 pt-2 pb-0 mb-0">
                                    <v-btn dense outlined color="primary" style="text-transform: capitalize" @click.native="Reset()">Reset</v-btn>
                                  </v-col>
                                </v-row>
                              </v-toolbar>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col v-for="dt in vStatCard.data" :key="dt.skey" cols="3" xs="6" lg="3">
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-container class="pa-0">
                        <div class="layout row ma-0">
                          <div class="sm12 xs12 flex text-sm-center py-3 white--text" :class="dt.color_box">
                            <span :class="fontPercent">{{ dt.series_qty }}</span>
                            <div :class="fontDeptname">{{ dt.categories_name }}</div>
                          </div>
                        </div>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" lg="12">
                  <v-card>
                    <apexchart ref = "chartBar" type="line" height="350" :options="chartOptions" :series="seriesBar"></apexchart>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab style="text-transform: capitalize" @click.native="retrieveBarMenu"> Visit Count By Menu </v-tab>
        <v-tab-item eager>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12">
              <v-card>
                <div class="layout row ma-0">
                  <div class="sm12 xs12 flex text-sm-center py-3 black--text div-1">
                    <!-- <span class="headline">Visit Count By Menu Dashboard</span> -->
                    <v-row>
                      <v-col cols="11">
                        <span class="headline">Visit Count By Menu Dashboard</span>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn
                              v-bind="attrs"
                              color="#E90587"
                              icon
                              v-on="on"
                              dark
                              @click.native="retrieveAll()"
                            >
                              <v-icon
                                color="#E90587"
                                dark
                              >
                                mdi-refresh
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>Refresh</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-progress-linear
                      :active="loading"
                      :indeterminate="loading"
                      absolute
                      bottom
                      color="#E90587"
                    ></v-progress-linear>
                  </div>
                </div>
              </v-card>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Criteria
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" sm="12" lg="12">
                            <div>
                              <v-toolbar flat>
                                <v-row>
                                  <v-col cols="12" sm="12" md="4" class="pt-1 mt-1 pb-0 mb-0">
                                    <v-dialog
                                      ref="dialogReceivedDateFromToMenu"
                                      v-model="dialog.dialogReceivedDateFromToMenu"
                                      :return-value.sync="criteria.ReceivedDateFromToMenu"
                                      persistent
                                      width="290px">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense
                                          hide-details
                                          outlined
                                          v-model="dateRangeTextMenu"
                                          label="Date"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="criteria.ReceivedDateFromToMenu"
                                        range
                                        scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dialog.dialogReceivedDateFromToMenu = false">{{$t('cancel')}}</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialogReceivedDateFromToMenu.save(criteria.ReceivedDateFromToMenu)">{{$t('ok')}}</v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-col>
                                  <!-- <v-col cols="12" class="pt-0 pb-2 mb-0" lg="3">
                                    <v-radio-group v-model="typeX" row dense hide-details>
                                      <v-radio class='text--primary'
                                        value="i"
                                      >
                                        <template v-slot:label>
                                          <div><span class='text--primary'>รายวัน</span></div>
                                        </template>
                                      </v-radio>
                                      <v-radio
                                        value="m"
                                      >
                                        <template v-slot:label>
                                          <div><span class='text--primary'>รายเดือน</span></div>
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                  </v-col> -->
                                  <v-col cols="12" xs="12" md="1" class="px-1 pt-2 pb-0 mb-0">
                                    <v-btn dense outlined color="primary" style="text-transform: capitalize" @click.native="retrieveBarMenu()">Retrieve</v-btn>
                                  </v-col>
                                  <v-col cols="12" xs="12" md="1" class="px-1 pt-2 pb-0 mb-0">
                                    <v-btn dense outlined color="primary" style="text-transform: capitalize" @click.native="ResetMenu()">Reset</v-btn>
                                  </v-col>
                                </v-row>
                              </v-toolbar>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col v-for="dt in vStatCard.data" :key="dt.skey" cols="3" xs="6" lg="3">
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-container class="pa-0">
                        <div class="layout row ma-0">
                          <div class="sm12 xs12 flex text-sm-center py-3 white--text" :class="dt.color_box">
                            <span :class="fontPercent">{{ dt.series_qty }}</span>
                            <div :class="fontDeptname">{{ dt.categories_name }}</div>
                          </div>
                        </div>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" lg="12">
                  <v-card>
                    <apexchart ref = "chartBarMenu" type="bar" height="350" :options="chartOptionsMenu" :series="seriesBarMenu"></apexchart>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios'
/* import ResultItemBrowse from '@/components/common/ResultItemBrowse'
import DepartmentBrowse from '@/components/common/DepartmentBrowse'
import InstrumentBrowse from '@/components/common/InstrumentBrowse' */
export default {
  components: {
    /* ResultItemBrowse,
    DepartmentBrowse,
    InstrumentBrowse */
  },

  data () {
    return {
      loading: false,
      typeX: 'i',
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      menuCriteria: false,
      colorHeader: 'gray',
      dateCriteria: new Date().toISOString().slice(0, 10),
      time: 0,
      polling: null,
      resultType: 'all',
      reviewedStatus: 'all',
      department: '',
      instrument: '',
      resultItem: '',
      resultItemSkey: '',
      deptId: '',
      analyzerSkey: '',
      sendVar: {
        PathoNo: ''
      },
      dialog: {
        dialogProgress: false,
        dialogBrowseResultItem: false,
        dialogBrowseDepartment: false,
        dialogBrowseInstrument: false,
        dialogReceivedDateFromTo: false,
        dialogReceivedDateFromToMenu: false,
      },
      criteria: {
        ReceivedDateFromTo: [new Date(Date.now() - 1209600000).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
        ReceivedDateFromToMenu: [new Date(Date.now() - 1209600000).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
        resultItemID: '',
        ReceivedLotNo: '',
        productClass: '',
        vendor: '',
        vendorName: '',
      },
      vBar: {
        search: '',
        data: [],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
        },
      },
      vBarMenu: {
        search: '',
        data: [],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
        },
      },
      vStatCard: {
        search: '',
        data: [],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
        },
      },
      vDonut: {
        search: '',
        data: [],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
        },
      },
      height1: 250,
      /* seriesBar: [], */
      seriesBar: [{
        name: 'visit-count',
        type: 'column',
        data: []
      }, {
        name: 'register-count',
        type: 'line',
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'line',
          height: 350,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        /* stroke: {
          width: [0, 4]
        }, */
        title: {
          text: 'Visit Count'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
      seriesBarMenu: [{
        name: 'visit-count',
        type: 'bar',
        data: []
      }],
      chartOptionsMenu: {
        chart: {
          type: 'bar',
          height: 350,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        /* stroke: {
          width: [0, 4]
        }, */
        title: {
          text: 'Visit Count'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
      seriesDonutIS: [44, 55, 41],
      chartOptionsDonutIS: {
        labels: ['Apple', 'Mango', 'Orange'],
        chart: {
          type: 'donut',
        },
        title: {
          text: 'XT3400'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 150
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesDonutIS1: [],
      chartOptionsDonutIS1: {
        labels: [],
        chart: {
          type: 'donut',
        },
        title: {
          text: ''
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 150
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesDonutIS2: [],
      chartOptionsDonutIS2: {
        labels: [],
        chart: {
          type: 'donut',
        },
        title: {
          text: ''
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 150
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesDonutIS3: [],
      chartOptionsDonutIS3: {
        labels: [],
        chart: {
          type: 'donut',
        },
        title: {
          text: ''
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 150
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesEx: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 81]
      }],
      chartOptionsBarEx: {
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
    }
  },

  computed: {
    fontDeptname () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'caption'
        case 'sm': return 'caption'
        case 'md': return 'headline'
        case 'lg': return 'headline'
        case 'xl': return 'headline'
      }
    },
    fontPercent () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'caption'
        case 'sm': return 'caption'
        case 'md': return 'headline'
        case 'lg': return 'headline'
        case 'xl': return 'headline'
      }
    },
    dateRangeText () {
      return this.criteria.ReceivedDateFromTo.join(' ~ ')
    },
    dateRangeTextMenu () {
      return this.criteria.ReceivedDateFromToMenu.join(' ~ ')
    },
  },

  watch: {
    loading (val) {
      if (!val) return

      setTimeout(() => (this.loading = false), 3000)
    },
  },

  created () {
    /* this.retrieveStatCard()
    this.retrieveDonut()
    this.retrieveBar() */
    this.retrieveBar()
    this.retrieveBarMenu()
    console.log('created')
    console.log(this.time)
  },

  mounted () {
    /* this.polling = setInterval(() => {
      this.time ++
      console.log(this.time, '==========this.time===========')
      this.formatDateTime()
      this.retrieveAll()
    }, 5000) */
  },
  beforeDestroy () {
    console.log('beforeDestroy')
    console.log(this.time)
    clearInterval(this.polling)
  },
  beforeCreate () {
    console.log('beforeCreate')
    console.log(this.time)
  },
  beforeMount () {
    console.log('beforeMount')
    console.log(this.time)
  },
  methods: {
    retrieveAll () {
      /* this.retrieveStatCard()
      this.retrieveDonut() */
      this.loading = true
      this.retrieveBar()
      this.retrieveBarMenu()
    },
    async retrieveBar () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      if (this.criteria.ReceivedDateFromTo.length > 0) {
        this.dateSearchFrom = this.criteria.ReceivedDateFromTo[0]
        if (this.criteria.ReceivedDateFromTo.length > 1) {
          this.dateSearchTo = this.criteria.ReceivedDateFromTo[1]
        } else {
          this.dateSearchTo = new Date().toISOString().slice(0, 10)
        }
      }
      this.axios.post(process.env.VUE_APP_API + '/visit_count/retrieveBar' + process.env.VUE_APP_DATABASE_TYPE, {
        type: this.typeX,
        date_from: this.dateSearchFrom,
        date_to: this.dateSearchTo,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vBar.data = response.data.data
            this.updateChartBar()
            this.dialog.dialogProgress = false
          }
        })
        .catch(e => {
          this.dialog.dialogProgress = false
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async retrieveBarMenu () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      if (this.criteria.ReceivedDateFromToMenu.length > 0) {
        this.dateSearchFrom = this.criteria.ReceivedDateFromToMenu[0]
        if (this.criteria.ReceivedDateFromToMenu.length > 1) {
          this.dateSearchTo = this.criteria.ReceivedDateFromToMenu[1]
        } else {
          this.dateSearchTo = new Date().toISOString().slice(0, 10)
        }
      }
      this.axios.post(process.env.VUE_APP_API + '/visit_count/retrieveBarMenu' + process.env.VUE_APP_DATABASE_TYPE, {
        type: this.typeX,
        date_from: this.dateSearchFrom,
        date_to: this.dateSearchTo,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vBarMenu.data = response.data.data
            this.updateChartBarMenu()
            this.dialog.dialogProgress = false
          }
        })
        .catch(e => {
          this.dialog.dialogProgress = false
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async retrieveStatCard () {
      var resultType = this.resultType
      var reviewedStatus = this.reviewedStatus
      var resultItemSkey = this.resultItemSkey
      var deptId = this.deptId
      var analyzerSkey = this.analyzerSkey
      var dateCriteria = this.dateCriteria
      if (!resultType) {
        resultType = 'all'
      }
      if (!reviewedStatus) {
        reviewedStatus = 'all'
      }
      if (!resultItemSkey) {
        resultItemSkey = '0'
      }
      if (!deptId) {
        deptId = ''
      }
      if (!analyzerSkey) {
        analyzerSkey = '0'
      }
      if (this.dateCriteria.length < 1) {
        dateCriteria = new Date().toISOString().slice(0, 10)
      }
      await this.axios.post(process.env.VUE_APP_API + '/visit_count/retrieveStatCard' + process.env.VUE_APP_DATABASE_TYPE, {
        type: 'i',
        pattern: 'StatCard',
        dateCriteria: dateCriteria,
        resultType: resultType,
        reviewedStatus: reviewedStatus,
        resultItemSkey: resultItemSkey,
        deptId: deptId,
        analyzerSkey: analyzerSkey,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vStatCard.data = response.data.data
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveDonut () {
      var resultType = this.resultType
      var reviewedStatus = this.reviewedStatus
      var resultItemSkey = this.resultItemSkey
      var deptId = this.deptId
      var analyzerSkey = this.analyzerSkey
      var dateCriteria = this.dateCriteria
      if (!resultType) {
        resultType = 'all'
      }
      if (!reviewedStatus) {
        reviewedStatus = 'all'
      }
      if (!resultItemSkey) {
        resultItemSkey = '0'
      }
      if (!deptId) {
        deptId = ''
      }
      if (!analyzerSkey) {
        analyzerSkey = '0'
      }
      if (this.dateCriteria.length < 1) {
        dateCriteria = new Date().toISOString().slice(0, 10)
      }
      await this.axios.post(process.env.VUE_APP_API + '/visit_count/retrieveDonut' + process.env.VUE_APP_DATABASE_TYPE, {
        type: 'i',
        pattern: 'Donut',
        dateCriteria: dateCriteria,
        resultType: resultType,
        reviewedStatus: reviewedStatus,
        resultItemSkey: resultItemSkey,
        deptId: deptId,
        analyzerSkey: analyzerSkey,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDonut.data = response.data.data
            this.updateDonut()
            /* this.seriesDonutIS = response.data.cars */
          } else {
            console.log('1111111111111111111111111111', 'SSSSS')
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    retrieveDonutSeries (skey) {
      var data = [100, 23, 20]
      return data
    },
    updateChartExample () {
      const max = 90
      const min = 20
      const newData = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min
      })

      const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']

      // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
      this.chartOptionsBarEx = {
        colors: [colors[Math.floor(Math.random() * colors.length)]]
      }
      // In the same way, update the series option
      this.seriesEx = [{
        data: newData
      }]
    },
    updateChart () {
      let series = []
      let categories = []
      var vBarDataFilter = this.vBar.data.filter(element => element.series_name === 'visit-count')
      for (const [key, value] of Object.entries(vBarDataFilter)) {
        series.push(value.series_qty)
        categories.push(value.categories_name)
      }
      // In the same way, update the series option
      this.seriesEx = [{
        data: series
      }]
      // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
      this.chartOptionsBarEx = {
        /* colors: [colors[Math.floor(Math.random() * colors.length)]], */
        xaxis: {
          categories: categories,
        },
      }
    },
    updateChartBar () {
      let series = []
      let categories = []
      let series2 = []
      let seriesColor = '#D3D3D3'
      let seriesColor2 = '#808080'
      var vBarDataFilter = this.vBar.data.filter(element => element.series_name === 'visit-count')
      for (const [key, value] of Object.entries(vBarDataFilter)) {
        series.push(value.series_qty)
        categories.push(value.categories_name)
        seriesColor = value.color_bar
      }
      var vBarDataFilter2 = this.vBar.data.filter(element => element.series_name === 'register-count')
      for (const [key, value] of Object.entries(vBarDataFilter2)) {
        series2.push(value.series_qty)
        seriesColor2 = value.color_bar
      }
      // In the same way, update the series option
      this.seriesBar = [{
        name: 'visit-count',
        data: series,
        color: seriesColor
      }, {
        name: 'register-count',
        data: series2,
        color: seriesColor2
      }]
      // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
      this.chartOptions = {
        /* colors: '#1E90FF', */
        xaxis: {
          categories: categories,
        },
        chart: {
          toolbar: {
            show: false
          },
        },
      }
    },
    updateChartBarMenu () {
      let series = []
      let categories = []
      let series2 = []
      let seriesColor = '#D3D3D3'
      let seriesColor2 = '#808080'
      var vBarDataFilter = this.vBarMenu.data.filter(element => element.series_name === 'visit-count')
      console.log(vBarDataFilter, 'vBarDataFilter')
      for (const [key, value] of Object.entries(vBarDataFilter)) {
        series.push(value.series_qty)
        categories.push(value.categories_name)
        seriesColor = value.color_bar
      }
      var vBarDataFilter2 = this.vBarMenu.data.filter(element => element.series_name === 'register-count')
      for (const [key, value] of Object.entries(vBarDataFilter2)) {
        series2.push(value.series_qty)
        seriesColor2 = value.color_bar
      }
      // In the same way, update the series option
      this.seriesBarMenu = [{
        name: 'visit-count',
        data: series,
        color: seriesColor
      }]
      // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
      this.chartOptionsMenu = {
        /* colors: '#1E90FF', */
        xaxis: {
          categories: categories,
        },
        chart: {
          toolbar: {
            show: false
          },
        },
      }
    },
    updateDonut () {
      let series = []
      let seriesColor = []
      let categories = []
      let title1 = ''
      let series2 = []
      let seriesColor2 = []
      let categories2 = []
      let title2 = ''
      let series3 = []
      let seriesColor3 = []
      let categories3 = []
      let title3 = ''
      var DataDonut1 = this.vDonut.data
      var DataDonut2 = this.vDonut.data
      var DataDonut3 = this.vDonut.data
      var vBarDataFilter = DataDonut1.filter(element => element.InstrumentShow === 'Top1')
      for (const [key, value] of Object.entries(vBarDataFilter)) {
        series.push(parseInt(value.series_qty))
        categories.push(value.result_status)
        title1 = value.Instrument
        seriesColor.push(value.color_series)
      }
      var vBarDataFilter2 = DataDonut2.filter(element => element.InstrumentShow === 'Top2')
      for (const [key, value] of Object.entries(vBarDataFilter2)) {
        series2.push(parseInt(value.series_qty))
        categories2.push(value.result_status)
        title2 = value.Instrument
        seriesColor2.push(value.color_series)
      }
      var vBarDataFilter3 = DataDonut3.filter(element => element.InstrumentShow === 'Top3')
      for (const [key, value] of Object.entries(vBarDataFilter3)) {
        series3.push(parseInt(value.series_qty))
        categories3.push(value.result_status)
        title3 = value.Instrument
        seriesColor3.push(value.color_series)
      }
      // In the same way, update the series option
      this.$refs.chartDonut1.updateOptions({
        series: series,
        colors: seriesColor,
        title: {
          text: title1
        },
        labels: categories,
      })
      this.$refs.chartDonut2.updateOptions({
        series: series2,
        colors: seriesColor2,
        title: {
          text: title2
        },
        labels: categories2,
      })
      this.$refs.chartDonut3.updateOptions({
        series: series3,
        colors: seriesColor3,
        title: {
          text: title3
        },
        labels: categories3,
      })
    },
    Reset () {
      this.typeX = 'i'
      this.criteria.ReceivedDateFromTo = [new Date(Date.now() - 1209600000).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)]
    },
    ResetMenu () {
      this.typeX = 'i'
      this.criteria.ReceivedDateFromToMenu = [new Date(Date.now() - 1209600000).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)]
    },
    getVarReturn (value) {
      switch (value.childSend) {
        case 'ResultItemBrowse':
          this.dialog.dialogBrowseResultItem = false
          switch (value.actionReturn) {
            case 'OK':
              for (let data of value.dataSelected) {
                this.resultItemSkey = data.result_item_skey
                this.resultItem = data.result_item_desc
              }
          }
          break
        case 'DepartmentBrowse':
          this.dialog.dialogBrowseDepartment = false
          switch (value.actionReturn) {
            case 'OK':
              for (let data of value.dataSelected) {
                this.deptId = data.dept_id
                this.department = data.description
              }
          }
          break
        case 'InstrumentBrowse':
          this.dialog.dialogBrowseInstrument = false
          switch (value.actionReturn) {
            case 'OK':
              for (let data of value.dataSelected) {
                this.analyzerSkey = data.analyzer_skey
                this.instrument = data.analyzer_desc
              }
          }
          break
      }
    },
    formatDateTime () {
      var today = new Date()
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      var date = today.getDate() + ' ' + months[(today.getMonth() + 1)] + ' ' + (today.getFullYear() + 543)
      var time = (today.getHours() < 10 ? '0' : '') + today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes() + ':' + (today.getSeconds() < 10 ? '0' : '') + today.getSeconds()
      var dateTime = date + ' ' + time
      return dateTime
    },
  },
}
</script>
<style scoped>
.div-1 {
  background-color: rgb(227, 227, 226);
}
/* background-color: rgb(189, 193, 187); */
</style>
